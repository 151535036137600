import React, { useEffect, useMemo, useState } from "react";

export const AnimatedSwitcherList = React.memo(({ className, children, delay = 2000 }) => {
    const [index, setIndex] = useState(-1);
    const childrenArray = React.Children.toArray(children);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % childrenArray.length);
        }, delay);

        return () => clearInterval(interval);
    }, [childrenArray.length, delay]);

    const itemsToShow = useMemo(
        () => childrenArray.slice(0, index + 1).reverse(),
        [index, childrenArray],
    );

    return (
        <ul className={`${className}`}>
            {itemsToShow.map((item) => (
                <AnimatedSwitcherListItem key={item.key}>
                    {item}
                </AnimatedSwitcherListItem>
            ))}
        </ul>
    );
});

AnimatedSwitcherList.displayName = "AnimatedSwitcherList";

export function AnimatedSwitcherListItem({ children }) {
    return (
        <li className="animated-list-item enter">
            {children}
        </li>
    );
}
