import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as queryString from 'query-string';
import ContentPageHeader from 'components/sections/Content_Page_Header.js';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';
import LinkCreator from 'utils/Link_Creator.js';
import ActionButton from 'components/buttons/Action_Button.js';
import CardProgram from 'components/affiliates/Card_Program.js';
// import TestimonialsСarrousel from 'components/affiliates/Testimonials_Сarrousel.js';
import { AnimatedSwitcherList, AnimatedSwitcherListItem } from 'components/wrappers/Animated_Switcher_List.js';
import ToolsItem from 'components/affiliates/Tools_Item.js';
import { RedirectWithLang, redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import Joining from 'utils/Joining.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

function Affiliates() {
    const [maxHeight, setMaxHeight] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const startDiscordAuth = () => {
        window.location.href = LinkCreator.createDiscordLink();
    };

    const setToDiscordToDiscordCommunityAfterAuth = () => {
        UserData.setItem(userDataKeys.redirect_to_discord_community_after_auth, true);
    };

    const isSetToRedirectToDiscordCommunity = () => {
        return (UserData.getItem(userDataKeys.redirect_to_discord_community) !== null);
    };

    const redirectToDiscordCommunity = () => {

        UserData.removeItem(userDataKeys.redirect_to_discord_community);
        window.location.href = discordCommunityURL;

    };

    useEffect(() => {

        const urlParams = queryString.parse(window.location.search);

        const discord = urlParams.discord;
        const community = urlParams.community;

        if (community === '1') {
            setToDiscordToDiscordCommunityAfterAuth();
        }
        if (location.key === 'default' && document.referrer.indexOf('discord.com') !== -1 || discord === '1') {
            startDiscordAuth();
        }

        if (isSetToRedirectToDiscordCommunity()) {
            redirectToDiscordCommunity();
        }

        Mixpanel.trackByArgs(configuration.mixpanel_events.affiliate_page_view, {});

        setTimeout(() => {
            const cards = document.querySelectorAll('.affiliate-program-card');
            const heights = Array.from(cards).map(card => {
                return card.offsetHeight;
            });
            const maxCardHeight = Math.max(...heights);
            setMaxHeight(maxCardHeight);
        }, 1000);

    }, []);

    const handleActionButtons = () => {
        if (ApiFacade.isLoggedIn()) {
            redirectWithLangFunc(configuration.paths.my_team_referrals)
        } else {
            Joining.showPopup(navigate);
        }
    }
    return (
        <div id="main">
            <div className='padding-bottom-85' id="content">
                <ContentPageHeader />
                <section role='affiliates-head' className="affiliates-section padding-top-80">
                    <div className="affiliates-shadow"></div>
                    <h1 className="affiliates-head">
                        <span className="green-text">{Content.getValue('affiliates_heading_1')}</span>
                        {Content.getValue('affiliates_heading_2')}
                    </h1>
                    <img src={Images.imageURL('affiliates/phones-new.png')} height='287' className="affiliates-phones" />
                    <h4 className="text-desc-white big relative margin-bottom-32-40">{Content.getValue('affiliates_earn_from')}</h4>
                    <ActionButton
                        onClick={handleActionButtons}
                        classNames='relative affiliates-join-button margin-bottom-32-40'
                        content={Content.getValue('affiliates_join_now')}
                    />
                    <p className="affiliates-1-desc">{Content.getValue('affiliates_head_desc')}</p>
                </section>
                <section role='how-affiliates-work' className="affiliates-section">
                    <h2 className="heading-2-white">{Content.getValue('affiliates_2_head')}</h2>
                    <ul className="affiliates-program-cards">
                        {[1, 2, 3].map(index => <CardProgram key={'CardProgram' + index} index={index} maximumHeight={maxHeight} />)}
                    </ul>
                </section>
                <section role='how-affiliates-work' className="affiliates-section relative">
                    <div className="affiliates-designed-for-wrapper">
                        <h2 className="heading-2-white text-500">{Content.getValue('affiliates_3_head')}</h2>
                        <div className='affiliates-designed-for-image' style={{ backgroundImage: `url(${Images.imageURL('affiliates/designed-for.png')})` }}  >
                            <p className="text-first">{Content.getValue('affiliates_3_regular_users')}</p>
                            <p className="text-second">{Content.getValue('affiliates_3_casino_players')}</p>
                            <p className="text-third">{Content.getValue('affiliates_3_media_buyers')}</p>
                        </div>
                        <div className='affiliates-designed-for-image desktop' style={{ backgroundImage: `url(${Images.imageURL('affiliates/designed-for-desktop.png')})` }}  >
                            <p className="text-first">{Content.getValue('affiliates_3_regular_users')}</p>
                            <p className="text-second">{Content.getValue('affiliates_3_casino_players')}</p>
                            <p className="text-third">{Content.getValue('affiliates_3_media_buyers')}</p>
                        </div>
                        <ActionButton
                            onClick={handleActionButtons}
                            classNames='affiliates-join-button'
                            content={Content.getValue('affiliates_join_now')}
                        />
                    </div>
                </section>
                <section role='why-treatcoin' className="affiliates-section">
                    <h3 className="affiliates-why-tc-heading">
                        <span>{Content.getValue('affiliates_4_why_treatcoin')}</span>
                        <img src={Images.imageURL('affiliates/why-choose-tc.png')} alt="" />
                    </h3>
                    <p className="affiliates-why-tc-desc">{Content.getValue('affiliates_4_why_treatcoin_desc')}</p>
                    <div className='relative affiliates-why-tc-graph'>
                        <img src={Images.imageURL('affiliates/why-tc-graph.png')} className='affiliates-why-tc-graph' />
                        <img src={Images.imageURL('affiliates/why-tc-moneybag-2.png')} className='affiliates-why-tc-moneybag' />
                    </div>
                </section>
                {/* <section role='feedbacks' className="affiliates-section">
                    <h3 className="heading-2-white max-w-75">
                        {Content.getValue('affiliates_5_what_affiliates_saying')}
                    </h3>
                    <TestimonialsСarrousel />
                </section> */}
                <section role='tools' className="affiliates-section">
                    <h2 className="heading-2-white">
                        {Content.getValue('affiliates_6_head_1')}
                        <br className='mobile-enter' />
                        <span className="green-text">{Content.getValue('affiliates_6_head_2')}</span>
                    </h2>

                    <AnimatedSwitcherList className={'animated-list'} >
                        {[1, 2, 3].map(index =>

                            <AnimatedSwitcherListItem key={'animated-item' + index} >
                                <ToolsItem index={index} />
                            </AnimatedSwitcherListItem>
                        )}
                    </AnimatedSwitcherList>
                </section>
                <section role='ready-to-join' className="affiliates-section affiliates-ready-to-join"
                    style={{ backgroundImage: `url(${Images.imageURL('affiliates/affiliates-7-background.png')})` }}>
                    <h2 className="heading-2-white">{Content.getValue('affiliates_7_head')}</h2>
                    <ActionButton
                        onClick={handleActionButtons}
                        classNames='affiliates-white-join-btn'
                        content={Content.getValue('affiliates_7_button')}
                    />
                </section>

                <footer className='affiliates-footer'>
                    <div className="affiliates-footer-links">
                        <div className="affiliates-footer-row">
                            <RedirectWithLang
                                to={configuration.paths.terms_of_use}
                                text={Content.getValue('affiliates_footer_link_1')}
                                props={{ className: 'affiliates-footer-link' }}
                                />
                            <RedirectWithLang
                                to={configuration.paths.privacy_policy}
                                text={Content.getValue('affiliates_footer_link_3')}
                                props={{ className: 'affiliates-footer-link' }}
                                />
                            <RedirectWithLang
                                to={configuration.paths.cookie_policy}
                                props={{ className: 'affiliates-footer-link' }}
                                text={Content.getValue('affiliates_footer_link_4')}
                            />
                        </div>
                    </div>
                    <p className="affiliates-footer-rights">
                        {Content.getValue('affiliates_footer_bottom_rights_1')}
                        <span className='purple-text'>{Content.getValue('affiliates_footer_bottom_rights_2')}</span>
                    </p>
                </footer>
            </div>
        </div>
    );
}

export default Affiliates;