import React from 'react';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';

const configuration = require('configuration.json');
const payoutStatutes = configuration.payout_statuses;

class CoinPayout extends React.Component {
    constructor(props) {
        super(props);
        this.isEarning = props.isEarning;
        this.bgColor = props.bgColor;
        this.isConversion = props.isConversion;
        this.state ={
            amount: props.amount,
            targetAmount: props.targetAmount,
            USDAmount: props.USDAmount,
            currency: props.currency,
            status: props.status,
            transactionID: props.transactionID,
            platform: props.platform,
            date: props.date,
            time: props.time,
            isConversion: props.isConversion,
        };
    }

    render() {
        const { 
            amount,
            targetAmount,
            USDAmount,
            currency,
            status,
            transactionID,
            platform,
            date,
            time,
            isConversion
        } = this.state;

        const imageUrl = isConversion ?
            Images.imageURL("robot-delete-account-icon.png") :
            Images.imageURL('payouts/' + platform.toLowerCase() + '.png');
        const actionAmount = isConversion ?
            targetAmount :
            USDAmount;

        const bgColor = this.bgColor;

        return (
            <div className={`treatcoin-payout-entry-wrapper mobile max-w-full gap-20 ${this.isEarning ? "earning" : ""} gradient-${bgColor} `}>
                <div className="treatcoin-payout-entry-left-section">
                    <img src={imageUrl} alt="Reward payout entry logo" className={`treatcoin-payout-${isConversion ? 'conversion' : 'entry-icon'}`} />
                </div>
                <div className="treatcoin-payout-entry-right-section margin-left-20">
                    <div className='treatcoin-payout-desc-container'>
                        {isConversion 
                            ? <div className="treatcoin-payout-description conversion">{Formatting.format(Content.getValue("coin_conversion_entry"), ["(usdAmount)", "(treatCoins)"], [amount.toLocaleString(), actionAmount.toLocaleString()])}</div>
                            : <div className="treatcoin-payout-description">{Formatting.format(Content.getValue("coin_payout_entry"), ["(currency)", "(usdAmount)", "(treatCoins)", "(platform)"], [currency, actionAmount.toFixed(2), Formatting.formatTreatCoinAmount(amount), platform])}</div>
                        }
                        <div className="treatcoin-payout-transaction-id">{Formatting.format(Content.getValue("payout_transaction_id"), ["(transactionID)"], [transactionID])}</div>
                    </div>
                    <div className="treatcoin-payout-date-container">
                        {!isConversion && (
                            <span className={`treatcoin-payout-status payout-status-${status}`}>{Content.getValue("payout_statuses")[status.toString()]}</span>
                        )}
                        <span className="treatcoin-payout-date">{(status !== payoutStatutes.pending && !isConversion) ? Content.getValue("at_label") : (null)} {date}, {time}</span>
                        {(status === payoutStatutes.rejected) && (
                            <div className="treatcoins-returned-label">{Formatting.format(Content.getValue("treatcoins_returned_to_balance"), ["(treatCoins)"], [Formatting.formatTreatCoinAmount(amount)])}</div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CoinPayout;