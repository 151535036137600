import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Earnings from 'utils/Earnings.js';
import Platform from 'utils/Platform.js';
import Formatting from 'utils/Formatting.js';
import CoinEarning from 'components/earnings/Coin_Earning.js';
import Content from 'utils/Content.js';
import CoinPayout from 'components/payouts/Coin_Payout.js';
import { Spinner } from 'react-spinners-css';

const configuration = require('configuration.json');

let thisComponent;
let options;
let earningCardsColors;
let earningCardsColorsArray;
const earningAvailabilityIds = configuration.earning_availability_ids;

class CoinEarningsList extends React.Component {
    constructor(props) {
        super(props);
        //this.myEarningsHistoryURL = myEarningsHistoryURL;
        this.currentYear = new Date().getUTCFullYear();
        this.currentMonth = new Date().getUTCMonth() + 1;
        this.coinsYear = this.currentYear;
        this.coinsMonth = this.currentMonth;
        this.minutesOffset = new Date().getTimezoneOffset() * (-1);
        this.coinsMonthDisplay = new Date(this.coinsYear, this.coinsMonth - 1, 1).toLocaleString(Content.getValue("date_localization"), { month: 'long' });
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            waitingToBeValidated: null,
            coinsEarnings: null,
            monthTotal: null,
            filter: null,
            registrationMonth: null,
            registrationYear: null,
            lifetimeCoinsEarned: null,
            availableToUse: null,
            competitionValid: null,
            coinsUsed: null,
            displayedItems: 5,
            totalItems: 5
        };
        this.handleScroll = function () {

            let isScrolling;
            if (isScrolling !== null) window.clearTimeout(isScrolling);

            isScrolling = setTimeout(function () {
                let element = document.getElementById('scroll-to-top-button');
                let docViewTop = window.scrollY;
                let docViewBottom = docViewTop + window.innerHeight;

                let elemTop = element?.offsetTop || 0;
                let elemBottom = elemTop + element?.offsetHeight || 0;

                let scrollingTriggered = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));

                if (scrollingTriggered) {
                    thisComponent.increaseDisplayedItems();
                }

            }, 50);

        };
        thisComponent = this;
    }

    increaseDisplayedItems() {

        const {displayedItems, totalItems} = this.state;

        let extraElements = 20;

        thisComponent.setState({
            displayedItems: Math.min(displayedItems + extraElements, totalItems)
        });
    }

    componentDidMount() {
        this.fetchScoresHistory();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    fetchScoresHistory() {
        let platform = Platform.getPlatformName();
        let req = ApiFacade.myEarningsHistory(this.coinsMonth,this.coinsYear, this.minutesOffset, platform);
        req.then(function (res) {
            if (res.ok){
                let result = res.response;
                thisComponent.setState({
                    isLoaded: true,
                    waitingToBeValidated: result.waitingToBeValidated,
                    coinsEarnings: result.coinsEarnings,
                    monthTotal: result.monthTotal,
                    filter: null,
                    registrationMonth: result.registrationMonth,
                    registrationYear: result.registrationYear,
                    lifetimeCoinsEarned: result.lifetimeCoinsEarned,
                    availableToUse: result.availableToUse,
                    competitionValid: result.competitionValid,
                    coinsUsed: result.coinsUsed,
                    totalItems: result.coinsEarnings.length,
                });
            } else if (res.unauthorized) {
                thisComponent.setState({
                    isLoaded: true,
                    isRestricted: true
                });
            } else {
                thisComponent.setState({
                    isLoaded: true,
                    error: res.error
                });
            }
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    changeCoinsMonth() {
        let monthSelection = document.getElementById('coins-month').value;
        
        this.coinsMonth = parseInt(monthSelection.split('-')[0]);
        this.coinsYear = parseInt(monthSelection.split('-')[1]);
        this.coinsMonthDisplay = new Date(this.coinsYear, this.coinsMonth - 1, 1).toLocaleString(Content.getValue("date_localization"), { month: 'long' });

        this.setState({
            error: null,
            isLoaded: false,            
            waitingToBeValidated: null,
            monthTotal: null,
            filter: null,
            coinsEarnings: null,
            lifetimeCoinsEarned: null,
            availableToUse: null,
            competitionValid: null,
            coinsUsed: null,
            displayedItems: 5,
            totalItems: 5
        });
        this.fetchScoresHistory();
    }

    generateMonthsList(firstMonth, firstYear) {
        let lastYear = thisComponent.currentYear;
        let lastMonth = thisComponent.currentMonth;
        let months = [];
        while (lastYear > firstYear || (lastYear === firstYear && lastMonth >= firstMonth)) {
            months.push({ monthAndYear: lastMonth + '-' + lastYear, monthName: new Date(lastYear, lastMonth - 1, 1).toLocaleString(Content.getValue("date_localization"), { month: 'long' }) + ' ' + lastYear });
            if (lastMonth > 1) {
                lastMonth--;
            }
            else {
                lastMonth = 12;
                lastYear--;
            }
        }
        return (
            months.map(item => (
                <option value={item.monthAndYear} key={item.monthAndYear}>{item.monthName}</option>
            ))
        );
    }

    generateOptionsList() {
        const fields = Object.values(options);

        return (
            fields.map(item => (
                <option className='options-list-item' value={item} key={item}>{item}</option>
            ))
        );
    }

    refreshCoinsList() {
        thisComponent.setState(prev => ({ ...prev, isLoaded: false, }));

        thisComponent.fetchScoresHistory();
    }

    filterHandler(value) {
        const { filter } = thisComponent.state;
        if (filter === value) return;
        thisComponent.setState(prev => ({ ...prev, filter: value }));
    }

    render() {
        earningCardsColors = Content.getValue("earning_cards_colors");
        earningCardsColorsArray = Object.values(earningCardsColors);
        options = Content.getValue("earning_filter_options");
        const { 
            error, 
            isLoaded, 
            isRestricted, 
            coinsEarnings, 
            monthTotal, 
            filter,
            lifetimeCoinsEarned, 
            waitingToBeValidated, 
            availableToUse, 
            competitionValid, 
            coinsUsed, 
            displayedItems, 
            registrationMonth, 
            registrationYear 
        } = this.state;

        let filteredEarnings, availableIds=[];
        if (filter === options.validated) availableIds = [earningAvailabilityIds["1"], earningAvailabilityIds["2"], earningAvailabilityIds["3"]];
        else if (filter === options.cooking) availableIds = [earningAvailabilityIds["4"]];
        else if (filter === options.downgraded) availableIds = [earningAvailabilityIds["5"]];

        filteredEarnings = (!filter || filter===options.all) ? 
            coinsEarnings :
            coinsEarnings.filter(each => availableIds.includes(each.coinsEarningAvailabilityID));

        if (error) {
            return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
        } else if (isRestricted) {
            return <div className="error-message">{Backend.loadBackendMessages().restrictedMessage}</div>;
        } else if (!isLoaded) {
            return <>
                <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>
                <Spinner color="#6BC6F5" />
            </> 
        } else {
            let displayingThisMonth = ((this.coinsYear === this.currentYear) && (this.coinsMonth === this.currentMonth));
            window.addEventListener('scroll', this.handleScroll, false);
            return (
                <div className="coins-history-section desktop">
                    <div className="coins-div desktop">
                        <div className="coins-month-selection-wrapper desktop">
                            <select className="coins-month-selection desktop" name="coins-month" id="coins-month" value={this.coinsMonth + '-' + this.coinsYear} onChange={function () { thisComponent.changeCoinsMonth(); }}>
                                {thisComponent.generateMonthsList(registrationMonth, registrationYear)}
                            </select>
                        </div>

                        <div className="month-total-container earning mobile">
                            <button className="button-hidden">
                                <img className="treat-coins-validity-hint-icon desktop" src={Images.imageURL('earnings/info-icon.png')} alt="Treat Coins validity hint" height="20" width="auto" onClick={() => Earnings.showTreatCoinsValidityHint()} />
                            </button>
                            <div className="lifetime-treat-coins-earned-label desktop">{Formatting.format(Content.getValue("lifetime_treat_coins_earned_label"), ["(lifetimeCoinsEarned)"], [Formatting.formatTreatCoinAmount(lifetimeCoinsEarned)])}</div>
                            <div className="monthly-treat-coins-label desktop">{Formatting.format(Content.getValue("total_treat_coins_earned_in_month"), ["(month)"], [thisComponent.coinsMonthDisplay])}</div>
                            <div className="monthly-treat-coins-amount-container desktop">
                                <div className="monthly-treat-coins-amount-number">{Formatting.formatTreatCoinAmount(monthTotal)}</div>
                                <div className="monthly-treat-coins-amount-tc">{Content.getValue("treat_coins")}</div>
                            </div>
                            <div className="monthly-treat-coins-breakdown-container desktop gap-20 dashed-top">
                                <div className="monthly-treat-coins-breakdown-item desktop">
                                    <div className="treatcoins-breakdown-title">{ Content.getValue("waiting_to_be_validated") }</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item desktop">
                                    <div className="treatcoins-breakdown-title">{displayingThisMonth ? Content.getValue("treat_coins_available_to_use") : Content.getValue("treat_coins_used")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item desktop">
                                    <div className="treatcoins-breakdown-title">{Content.getValue("competition_valid_treat_coins")}</div>
                                </div>
                            </div>
                            <div className="monthly-treat-coins-breakdown-container desktop gap-20">
                                <div className="monthly-treat-coins-breakdown-item desktop">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(waitingToBeValidated)}</div>
                                    <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item desktop">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(displayingThisMonth ? availableToUse : coinsUsed)}</div>
                                    <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item desktop">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(competitionValid)}</div>
                                    <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                </div>
                            </div>
                        </div>

                        <div className="month-total-container earning desktop gap-30">
                            <div className="month-total-container-left card">
                                <button onClick={() => Earnings.showTreatCoinsValidityHint()}  className="button-hidden">
                                    <img className="treat-coins-validity-hint-icon desktop" src={Images.imageURL('earnings/info-icon.png')} alt="Treat Coins validity hint" height="20" width="auto" />
                                </button>
                                <div className="lifetime-treat-coins-earned-label desktop">{Formatting.format(Content.getValue("lifetime_treat_coins_earned_label"), ["(lifetimeCoinsEarned)"], [Formatting.formatTreatCoinAmount(lifetimeCoinsEarned)])}</div>
                                <div className="monthly-treat-coins-label desktop">{Formatting.format(Content.getValue("total_treat_coins_earned_in_month"), ["(month)"], [thisComponent.coinsMonthDisplay])}</div>
                                <div className="monthly-treat-coins-amount-container desktop">
                                    <div className="monthly-treat-coins-amount-number desktop">{Formatting.formatTreatCoinAmount(monthTotal)}</div>
                                    <div className="monthly-treat-coins-amount-tc desktop">{Content.getValue("treat_coins")}</div>
                                </div>
                            </div>

                            <div className="month-total-container-right card">
                                <div className="monthly-treat-coins-breakdown-container desktop gap-20 w-full">
                                    <div className="monthly-treat-coins-breakdown-item desktop">
                                        <div className="treatcoins-breakdown-title">{ Content.getValue("waiting_to_be_validated") }</div>
                                    </div>
                                    <div className="monthly-treat-coins-breakdown-item desktop">
                                        <div className="treatcoins-breakdown-title">{displayingThisMonth ? Content.getValue("treat_coins_available_to_use") : Content.getValue("treat_coins_used")}</div>
                                    </div>
                                    <div className="monthly-treat-coins-breakdown-item desktop">
                                        <div className="treatcoins-breakdown-title">{Content.getValue("competition_valid_treat_coins")}</div>
                                    </div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-container desktop gap-20 w-full">
                                    <div className="monthly-treat-coins-breakdown-item desktop">
                                        <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(waitingToBeValidated)}</div>
                                        <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                    </div>
                                    <div className="monthly-treat-coins-breakdown-item desktop">
                                        <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(displayingThisMonth ? availableToUse : coinsUsed)}</div>
                                        <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                    </div>
                                    <div className="monthly-treat-coins-breakdown-item desktop">
                                        <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(competitionValid)}</div>
                                        <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="coins-month-selection-wrapper desktop filtered margin-bottom-30">
                        <p className='coins-month-selection-wrapper-desc'>Filtered by:</p>
                        <select 
                            className="coins-month-selection desktop" 
                            name="earning-filter" 
                            id="earning-filter" 
                            onChange={({ target: { value } }) => thisComponent.filterHandler(value)}
                        >
                            {thisComponent.generateOptionsList()}
                        </select>
                    </div>

                    <div className="coins-list-swipe desktop">
                        <button className="swipe-down-container desktop hidden-button" onClick={() => { thisComponent.refreshCoinsList(); }}>
                            <img className="refresh-my-treats-icon" alt="Refresh" width="28" height="28" src={Images.imageURL('refresh-icon.png')} ></img>
                        </button>
                    </div>

                    <div>
                        {filteredEarnings.slice(0, displayedItems).map((item, index) => item.isConversion===1 ?
                        (
                            <CoinPayout
                                key={index}
                                isEarning={true}
                                amount={item.amount}
                                targetAmount={item.targetAmount}
                                USDAmount={item.USDAmount}
                                status={item.status}
                                transactionID={item.transactionID}
                                platform={item.platform}
                                date={item.date}
                                time={item.time}
                                isConversion={item.isConversion}
                                bgColor={earningCardsColorsArray[index % earningCardsColorsArray.length]}
                            />
                        ) :
                        (
                                <CoinEarning
                                    key={index}
                                    coinsEarningAvailabilityID={item.coinsEarningAvailabilityID}
                                    providerName={item.providerName}
                                    logoURL={item.logoURL}
                                    description={item.description}
                                    referral={item.referral}
                                    referralType={item.referralType}
                                    amount={item.amount}
                                    USDAmount={item.USDAmount}
                                    date={item.date}
                                    time={item.time}
                                    transactionID={item.transactionID}
                                    referralEarningID={item.referralEarningID}
                                    coinsEarningValidity={item.coinsEarningValidity}
                                    moreOffersNumber={item.moreOffersNumber}
                                    appName={item.appName}
                                    position={item.position}
                                    bgColor={earningCardsColorsArray[index % earningCardsColorsArray.length]}
                                    isGold={item.isGold}
                                    AssetCollection={item.AssetCollection}
                                    AssetName={item.AssetName}
                                    AssetSerialNumber={item.AssetSerialNumber}
                                    AssetPrice={item.AssetPrice}
                                    InvoiceUniqueNumber={item.InvoiceUniqueNumber}
                                />
                        ))}
                    </div>
                </div>
            );
        }
    }

}

export default CoinEarningsList;