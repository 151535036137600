import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import LoadingPage from 'components/sections/Loading_Page.js';
import { GlobalContextProvider } from 'context/Global_Context';

import 'design/animations.css';
import 'design/home.css';
import 'design/buttons.css';
import 'design/checkboxes.css';
import 'design/divs.css';
import 'design/images.css';
import 'design/intro_survey.css';
import 'design/inputs.css';
import 'design/links.css';
import 'design/lists.css';
import 'design/login.css';
import 'design/playground.css';
import 'design/referral.css';
import 'design/scroller.css';
import 'design/selects.css';
import 'design/tables.css';
import 'design/texts.css';
import 'design/widgets.css';
import 'design/hot_offer.css';
import 'design/account_settings.css';
import 'design/general.css';
import 'design/one_two_treat.css';
import 'design/top_12_offers.css';
import 'design/offer_popup.css';
import 'design/bottom_menu.css';
import 'design/completed_layout.css';
import 'design/additional_offers.css';
import 'design/offers_clicked.css';
import 'design/join.css';
import 'design/popups.css';

import App from 'App.js';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import OfferEvents from 'utils/Offer_Events.js';
import Content from 'utils/Content.js';
import Consent from 'utils/Consent.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import Redirects from 'utils/Redirects.js';

window.backendClass = Backend;
Backend.buildUrls();

const configuration = require('configuration.json');

const tokenSourceTypes = configuration.token_source_types;
const userDataKeys = configuration.user_data_keys;
const queryVariables = configuration.query_variables;
const utmCampaigns = configuration.utm_campaigns;
const utmSources = configuration.utm_sources;

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

const token = getQueryVariable(queryVariables.token);
let source = getQueryVariable(queryVariables.source);
if (source !== null) {
    source = tokenSourceTypes[source];
}
const ref = getQueryVariable(queryVariables.ref);
const recommendedOffer = getQueryVariable(queryVariables.recommended_offer);
const offerRecommender = getQueryVariable(queryVariables.offer_recommender);
const emailOffer = getQueryVariable(queryVariables.email_offer);
const emailRefer = getQueryVariable(queryVariables.email_refer);
const code = getQueryVariable(queryVariables.code);
const confirmationCode = getQueryVariable(queryVariables.confirmation_code);
const email = getQueryVariable(queryVariables.email);
let utmSource = getQueryVariable(queryVariables.utm_source);
const utmMedium = getQueryVariable(queryVariables.utm_medium);
let utmCampaign = getQueryVariable(queryVariables.utm_campaign);

let currentUtmSource = UserData.getItem(userDataKeys.utm_source);
if (ref && !currentUtmSource && !ApiFacade.isLoggedIn() && !utmSource) {
    utmSource = utmSources.referral;
}
let currentUtmCampaign = UserData.getItem(userDataKeys.utm_campaign);
if (!currentUtmCampaign && !ApiFacade.isLoggedIn() && !utmCampaign) {
    utmCampaign = utmCampaigns.organic;
}

const utmContent = getQueryVariable(queryVariables.utm_content);
const utmTerm = getQueryVariable(queryVariables.utm_term);
let fbclid = null;
const fbclidParameter = getQueryVariable(queryVariables.fbclid);
if (fbclidParameter) {
    fbclid = 'fb.1.' + Date.now().toString() + '.' + getQueryVariable(queryVariables.fbclid);
}
const deviceId = getQueryVariable(queryVariables.device_id);

const appContentVersion = configuration.content_version;
const currentContentVersion = UserData.getItem(userDataKeys.content_version);

UserData.setItem(userDataKeys.content_version, appContentVersion);

function renderFunc() {
    if (currentContentVersion === null ||
        currentContentVersion !== appContentVersion ||
        !Content.getContent() ||
        Content.getContent() === "undefined" ||
        Content.getContent() === "null") {
        let preferredLanguage = Content.getPreferredLanguage();

        let req = ApiFacade.fetchResourceContent(preferredLanguage);
        req.then(async function (res) {
                if (res.ok) {
                    const result = res.response;
                    const content = result.content;
                    if (content) {
                        Content.storeContent(content);
                        renderDom();
                    }
                }
            },
                () => {
                }
            );
    }
    else {
        renderDom();
    }
}
const container = document.getElementById('root');
const root = createRoot(container);

function renderDom() {

    if (UserData.getItem(userDataKeys.account_suspended) !== null &&
        !Redirects.isCurrentWebpage(configuration.paths.account_suspended)) {
        redirectWithLangFunc(configuration.paths.account_suspended);
    }

    else if ((ApiFacade.isLoggedIn()) &&
        (code === null) &&
        (confirmationCode === null) &&
        (Redirects.isCurrentWebpage(configuration.paths.login) ||
            Redirects.isCurrentWebpage(configuration.paths.email_signup) ||
            Redirects.isCurrentWebpage(configuration.paths.forgot_password))
    ) {
        redirectWithLangFunc(configuration.paths.playground);
    }

    else if (ApiFacade.isLoggedIn() &&
        Redirects.recommendedOrEmailOfferSet() &&
        Redirects.isCurrentWebpage(configuration.paths.home)) {
        redirectWithLangFunc(configuration.paths.playground);
    }

    else {
        root.render(
            <React.StrictMode>
                <GlobalContextProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </GlobalContextProvider>
            </React.StrictMode>);
    }

}

function loadWebsite() {

    const thirdPartyAccepted = UserData.getItem(userDataKeys.third_party_accepted);
    if (thirdPartyAccepted === "true") {
        // added for backwards compatibility
        Backend.allowAllThirdPartyForRegisteringUser();
    }

    const terms = UserData.getItem(userDataKeys.terms);
    if (Number(terms) !== 1) {
        UserData.removeItem(userDataKeys.website_referral_link);
        UserData.removeItem(userDataKeys.telegram_referral_link);
    }

    const oldThirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings));
    if (oldThirdPartySettings) {
        let wrongGoogleAnalyticsKeyName = "gooogleAnalytics";
        let wrongGoogleAnalyticsValue = oldThirdPartySettings[wrongGoogleAnalyticsKeyName];
        if (wrongGoogleAnalyticsValue) {
            oldThirdPartySettings[userDataKeys.third_party_cookies.google_analytics] = wrongGoogleAnalyticsValue;
            delete oldThirdPartySettings[wrongGoogleAnalyticsKeyName];
            UserData.setItem(userDataKeys.third_party_settings, JSON.stringify(oldThirdPartySettings));
        }
    }

    const guestToken = UserData.getItem(userDataKeys.guest_token);

    const thirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings)) || {};
    const mixpanelAccepted = thirdPartySettings[userDataKeys.third_party_cookies.mixpanel] || false;
    const googleAnalyticsAccepted = thirdPartySettings[userDataKeys.third_party_cookies.google_analytics] || false;

    if (UserData.getItem(userDataKeys.offer_events) !== null) {
        let offerEvents = JSON.parse(UserData.getItem(userDataKeys.offer_events));
        for (let i = 0, len = offerEvents.length; i < len; i++) {
            OfferEvents.sendEvent(offerEvents[i]);
        }
        UserData.removeItem(userDataKeys.offer_events);
    }


    if (recommendedOffer !== null && offerRecommender !== null) {
        UserData.setItem(userDataKeys.recommended_offer, recommendedOffer);
        UserData.setItem(userDataKeys.offer_recommender, offerRecommender);
    }

    UserData.setItemWithValueCheck(userDataKeys.email_offer, emailOffer);
    UserData.setItemWithValueCheck(userDataKeys.email_refer, emailRefer);

    UserData.setItemWithValueCheck(userDataKeys.utm_source, utmSource);
    UserData.setItemWithValueCheck(userDataKeys.utm_medium, utmMedium);
    UserData.setItemWithValueCheck(userDataKeys.utm_campaign, utmCampaign);
    UserData.setItemWithValueCheck(userDataKeys.utm_content, utmContent);
    UserData.setItemWithValueCheck(userDataKeys.utm_term, utmTerm);
    UserData.setItemWithValueCheck(userDataKeys.fbclid, fbclid);
    if (Mixpanel.getDistinctId() === null || Mixpanel.getDistinctId() !== deviceId) {
        UserData.setItemWithValueCheck(userDataKeys.device_id, deviceId);
    }

    UserData.setItemWithValueCheck(userDataKeys.email_signup, email);

    const shouldFetchAuthTokenData = (token !== null && source);

    if (guestToken === null) {
        Backend.createGuestCookie(renderFunc, shouldFetchAuthTokenData, token, source);
    }
    else if (shouldFetchAuthTokenData) {
        const fetchUserDataAfterAnalytics = function () {
            Backend.fetchUserData(renderFunc, token, source);
        };
        Backend.startAfterAnalyticsInitialized(fetchUserDataAfterAnalytics);
    }
    else {
        renderFunc();
    }

    if (!UserData.getItem(userDataKeys.receive_live_news)) {
        UserData.setItem(userDataKeys.receive_live_news, true);
    }

    if (ref !== null) {
        UserData.setItem(userDataKeys.referrer_code, ref);
        UserData.removeItem(userDataKeys.jwt_token);
        UserData.removeItem(userDataKeys.login_type);
    }

    if (mixpanelAccepted) {
        Consent.addMixpanel();
    }
    if (googleAnalyticsAccepted) {
        Consent.addGoogleAnalyticsScripts();
    }

}

root.render(<LoadingPage />);

loadWebsite();


