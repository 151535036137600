import React from 'react';
import ActionButton from '../components/buttons/Action_Button';
import Images from '../utils/Images';

function SuccessPopup({ onClose, confirmBtnText, desc, head, isError }) {
    return (
        <div >

            <img src={Images.imageURL(isError ?'error-popup-icon.png':'check_circle_colorful.png')}
                className='success-popup-image'
            />
            {isError ?
                <></> :
                <h4 className='success-popup-head'>{head}</h4>
            }
            <p className={`success-popup-desc ${isError ?'red-text':''} `}>{desc}</p>
            <ActionButton
                content={confirmBtnText}
                onClick={onClose}
            />
        </div>
    );
}

export default SuccessPopup;