import React from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';

function ToolsItem({index}) {
    return (
        <div className='affiliates-tools-item'>
            <img src={Images.imageURL(`affiliates/tools-image-${index}.png`)} className='affiliates-tools-item-img' />
            <div className="affiliates-tools-item-right">
                <h6>{Content.getValue(`affiliates_6_tools_head_${index}`)}</h6>
                <p>{Content.getValue(`affiliates_6_tools_desc_${index}`)}</p>
            </div>
        </div>
    );
}

export default ToolsItem;