import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Route, useLocation, useNavigate, Routes } from 'react-router-dom';
import AboutUs from 'webpages/About_Us.js';
import AccountSettings from 'webpages/Account_Settings.js';
import AccountSuspended from 'webpages/Account_Suspended.js';
import ConnectToDiscord from 'webpages/Connect_To_Discord.js';
import CookiePolicy from 'webpages/Cookie_Policy.js';
import EmailSignup from 'webpages/Email_Signup.js';
import FAQ from 'webpages/FAQ.js';
import Homepage from 'webpages/Homepage.js';
import IntroSurvey from 'webpages/Intro_Survey.js';
import IntroSurveyFinished from 'webpages/Intro_Survey_Finished.js';
import Leaderboards from 'webpages/Leaderboards.js';
import Login from 'webpages/Login.js';
import MyTeamReferrals from 'webpages/My_Team_Referrals.js';
import MyTreatCoinEarnings from 'webpages/My_Treat_Coin_Earnings.js';
import MyTreatCoinPayouts from 'webpages/My_Treat_Coin_Payouts.js';
import NotificationSettings from 'webpages/Notification_Settings.js';
import Playground from 'webpages/Playground.js';
import PrivacyPolicy from 'webpages/Privacy_Policy.js';
import StartYourJourney from 'webpages/Start_Your_Journey.js';
import TermsOfUse from 'webpages/Terms_Of_Use.js';
import Top12Offers from 'webpages/Top_12_Offers';
import TreatCoinsMission from 'webpages/TreatCoins_Mission.js';
import Cashout from 'webpages/Cashout.js';
import Content from 'utils/Content.js';
import UserData from 'User_Data.js';
import { SpinnerContext } from 'context/Global_Spinner.js';
import SocialLoginOverlay from 'components/login/Social_Login_Overlay';
import Menu from 'components/widgets/Menu_Drawer.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import BottomMenu from 'components/widgets/Bottom_Menu';
import OffersClicked from 'webpages/Offers_Clicked';
import OfferPopup from 'components/offers/popup/Offer_Popup';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import MainLayout from 'layouts/MainLayout';
import MyReviews from 'webpages/My_Reviews';
import Affiliates from './webpages/Affiliates';

const configuration = require('configuration.json');
const langData = configuration.languages;
const userDataKeys = configuration.user_data_keys;

let accountSuspended = UserData.getItem(userDataKeys.account_suspended);

const HistoryScrollingWrapper = ({ location, children }) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return children;
};

const Main = () => {
    const [langPrefix, setLangPrefix] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const { isVisible, spinnerText } = useContext(SpinnerContext);
    const url = location.pathname;
    let match = url.match(/^\/([a-z]{2})\//);
    if (!match) {
        match = url.match(/^\/([a-z]{2})$/);
    }

    const asyncUpdateLanguage = async (value) => {
        await Content.updatePreferredLanguage(value);
        handleLangPrefix();
    };
    
    const HandleRedirect = () => {
        redirectWithLangFunc(url, navigate);
    };

    let langCode = match ? langData.find(i => i.url_code === match[1])?.code : null;

    const handleLangPrefix = () => {
        let preferredLanguage = Content.getPreferredLanguage();
        if (preferredLanguage !== 'en_US') {
            setLangPrefix('/' + preferredLanguage.slice(0, 2));
        } else {
            setLangPrefix('');
        }
    };


    useEffect(() => {

        langCode = match ? langData.find(i => i.url_code === match[1])?.code : '';
        const storedLangCode = Content.getPreferredLanguage();
        const hasCodeMatch = langData.some(i => i.code === storedLangCode);
        if (langCode) {
            if (!storedLangCode || storedLangCode !== langCode) {
                asyncUpdateLanguage(langCode);
            }
        } else if (!hasCodeMatch) {
            asyncUpdateLanguage('en_US');
        } else if (storedLangCode !== null && storedLangCode !== langData[0].code && hasCodeMatch) {
            HandleRedirect();
        }
    }, [match, url, Content.getPreferredLanguage()]);

    useEffect(() => {
        if (accountSuspended) {
            redirectWithLangFunc(configuration.paths.account_suspended, navigate);
        }
    }, [url, accountSuspended]);

    useEffect(() => {
        handleLangPrefix();
    }, [url]);

    return (
        <>
            <Menu navigate={navigate} />
            <BottomMenu />
            {isVisible &&
                <SocialLoginOverlay text={spinnerText} />
            }
            <OfferPopup />
            <HistoryScrollingWrapper location={location}>
                <SwitchTransition component={null}>
                    <CSSTransition key={location.key} classNames="fade" timeout={300}>

                        <Routes location={location}>
                            <Route exact path={`${langPrefix}${configuration.paths.welcome}`} element={
                                <MainLayout contentKey="home" component={
                                    <Homepage />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.leaderboards}`} element={
                                <MainLayout contentKey="leaderboards" component={
                                    <Leaderboards />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.playground}`} element={
                                <MainLayout contentKey="playground" component={
                                    <Playground />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.faq}`} element={
                                <MainLayout contentKey="faq" component={
                                    <FAQ />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.about_us}`} element={
                                <MainLayout contentKey="about_us" component={
                                    <AboutUs />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.treatcoins_mission}`} element={
                                <MainLayout contentKey="treatcoins_mission" component={
                                    <TreatCoinsMission />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.terms_of_use}`} element={
                                <MainLayout contentKey="terms_of_use" component={
                                    <TermsOfUse />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.privacy_policy}`} element={
                                <MainLayout contentKey="privacy_policy" component={
                                    <PrivacyPolicy />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.cookie_policy}`} element={
                                <MainLayout contentKey="cookie_policy" component={
                                    <CookiePolicy />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.login}`} element={
                                <MainLayout contentKey="login" component={
                                    <Login />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.email_signup}`} element={
                                <MainLayout contentKey="email_signup" component={
                                    <EmailSignup />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.my_treat_coin_earnings}`} element={
                                <MainLayout contentKey="my_treat_coin_earnings" component={
                                    <MyTreatCoinEarnings />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.my_treat_coin_payouts}`} element={
                                <MainLayout contentKey="my_treat_coin_payouts" component={
                                    <MyTreatCoinPayouts />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.my_team_referrals}`} element={
                                <MainLayout contentKey="my_team_referrals" component={
                                    <MyTeamReferrals />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.account_settings}`} element={
                                <MainLayout contentKey="account_settings" component={
                                    <AccountSettings />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.notification_settings}`} element={
                                <MainLayout contentKey="notification_settings" component={
                                    <NotificationSettings />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.account_suspended}`} element={
                                <MainLayout contentKey="account_suspended" component={
                                    <AccountSuspended />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.connect_to_discord}`} element={
                                <MainLayout contentKey="connect_to_discord" component={
                                    <ConnectToDiscord />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.intro_survey}`} element={
                                <MainLayout contentKey="intro_survey" component={
                                    <IntroSurvey />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.intro_survey_finished}`} element={
                                <MainLayout contentKey="intro_survey_finished" component={
                                    <IntroSurveyFinished />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.start_your_journey}`} element={
                                <MainLayout contentKey="start_your_journey" component={
                                    <StartYourJourney />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.top_12_offers}`} element={
                                <MainLayout contentKey="top_12_offers" component={
                                    <Top12Offers />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.offers_clicked}`} element={
                                <MainLayout contentKey="offers_clicked" component={
                                    <OffersClicked />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.cashout}`} element={
                                <MainLayout contentKey="cashout" component={
                                    <Cashout />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.my_reviews}`} element={
                                <MainLayout contentKey="my_reviews" component={
                                    <MyReviews />
                                } />
                            } />

                            <Route exact path={`${langPrefix}${configuration.paths.search_for_reviews}`} element={
                                <MainLayout contentKey="search_for_reviews" component={
                                    <MyReviews activeTab={1}/>
                                } />
                            } />
                            <Route exact path={`${langPrefix}${configuration.paths.affiliates}`} element={
                                <MainLayout contentKey="affiliates" component={
                                    <Affiliates />
                                } />
                            } />

                            {/**** Homapage should be last route ****/}
                            < Route exact path={`${langPrefix}${configuration.paths.home}`} element={
                                <MainLayout contentKey="home" component={
                                    < Homepage />
                                } />
                            } />
                        </Routes>
                    </CSSTransition>
                </SwitchTransition>
            </HistoryScrollingWrapper>
        </>
    );
};

export default Main;
