import React, { useState, useRef } from 'react';
import Images from 'utils/Images.js';
import Select from 'components/inputs/Select';
import TextInput from 'components/inputs/TextInput';
import DataValidator from 'utils/Data_Validator';
import Content from 'utils/Content.js';

const PixPaymentForm = props => {

    const PixTypeDropdown = [
        { label: "CPF", value: "CPF" },
        { label: Content.getValue("payment_method_pix_phone_number"), value: "phoneNumber" },
        { label: "E-mail", value: "email" },
        { label: Content.getValue("payment_method_pix_random_key"), value: "randomKey" },
    ];
    
    const { onSubmit, onGoBack } = props;

    const [pixType, setPixType] = useState(null);
    const [validationErrorMsg, setValidationErrorMsg] = useState({});
    const [phoneNumber, setPhoneNumber] = useState("");
    const [cpf, setCpf] = useState("");

    const emailRef = useRef(null);
    const randomKeyRef = useRef(null);

    const styles = {
        formField: {
            marginTop: 15
        }
    }

    const onPhoneNumberChange = (value) => {
        const cleaned = value.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})$/);
        let formatted = value;

        if (match) {
          const parts = [
            match[1] ? `(${match[1]}` : '',
            match[2] ? `) ${match[2]}` : '',
            match[3] ? ` ${match[3]}` : '',
            match[4] ? ` ${match[4]}` : ''
          ];
          formatted = parts.join('').trim();
        }
        
        setPhoneNumber(formatted);
    };

    const onCpfChange = (value) => {
        const numbers = value.replace(/\D/g, '');
        const chars = numbers.split('');
        let formatted = '';
    
        for (let i = 0; i < chars.length; i++) {
          if (i === 3 || i === 6) formatted += '.';
          if (i === 9) formatted += '-';
          formatted += chars[i];
        }
            
        setCpf(formatted);
    };

    const validateForm = () => {
        if(!pixType){
            return;
        }

        let res;

        const isValidCPF = DataValidator.isValidPaymentMethod_Pix_CPF(cpf)
        setValidationErrorMsg(prev => ({...prev, CPF: (!isValidCPF ? Content.getValue("payment_method_pix_validation_error_CPF") : "")}))

        let isValidphoneNumber, isValidEmail, isValidRandomKey;
        switch(pixType){
            case 'phoneNumber':
                res = {phoneNumber: phoneNumber};
                isValidphoneNumber = DataValidator.isValidPhoneNumber_brazil(phoneNumber);
                setValidationErrorMsg(prev => ({...prev, phoneNumber: (!isValidphoneNumber ? Content.getValue("payment_method_pix_validation_error_phone_number") : "")}));
                break;
            case 'email':
                res = {email: emailRef.current.value};
                isValidEmail = DataValidator.isValidEmail(emailRef.current.value);
                setValidationErrorMsg(prev => ({...prev, email: (!isValidEmail ? Content.getValue("payment_method_pix_validation_error_email") : "")}));
                break;
            case 'randomKey':
                res = {randomKey: randomKeyRef.current.value};
                isValidRandomKey = DataValidator.isValidPaymentMethod_Pix_randomKey(randomKeyRef.current.value);
                setValidationErrorMsg(prev => ({...prev, randomKey: (!isValidRandomKey ? Content.getValue("payment_method_pix_validation_error_random_key") : "")}));
                break;
            default:
                break;
        }
        
        if(isValidCPF && (!validationErrorMsg.hasOwnProperty(pixType) || validationErrorMsg[pixType]?.length == 0)){
            onSubmit({
                ...{pixType, CPF: cpf}, 
                ...res
            })
        }
       
    };


    return (
        <div className='font-14'>
            <div onClick={() => onGoBack()} className='pix-form-back'><img src={Images.imageURL(`arrow_back_dark.png`)} height="18" width="18" /></div>
            <div><img src={Images.imageURL(`payment-methods/pix-icon-green.png`)} alt="Pix account" height="54" /></div>
            <div>{Content.getValue('payment_method_pix_form_title')}</div>
            <div style={styles.formField}>
                <Select
                    label={Content.getValue("payment_method_pix_form_pix_type")}
                    data={PixTypeDropdown}
                    onChange={setPixType}
                    placeHolder={Content.getValue("payment_method_pix_form_pix_type")}
                />
            </div>
            {pixType == "phoneNumber" && (
                <TextInput
                    label={Content.getValue("payment_method_pix_form_phone_number")}
                    error={validationErrorMsg.phoneNumber}
                    onChange={onPhoneNumberChange}
                    value={phoneNumber}
                    maxLength={16}
                    placeHolder="(55) 9 1234 5678"
                />
            )}
            {pixType == "email" && (
                <TextInput
                    label="E-mail"
                    ref={emailRef}
                    error={validationErrorMsg.email}
                />
            )}
            {pixType == "randomKey" && (
                <TextInput
                    label={Content.getValue("payment_method_pix_form_random_key")}
                    ref={randomKeyRef}
                    error={validationErrorMsg.randomKey}
                />
            )}
            {pixType && (
                <TextInput
                    label="CPF"
                    error={validationErrorMsg.CPF}
                    placeHolder="123.456.789-21"
                    onChange={onCpfChange}
                    value={cpf}
                    maxLength={14}
                />
            )}

            <button disabled={!pixType} onClick={() => validateForm()} className="start-withdraw-button">{Content.getValue("continue_button_text")}</button>
        </div>
    );

}



export default PixPaymentForm;